<template>
  <div>
    <b-form-group
      :label="$getVisibleNames('teaching.section', false, 'Sección')"
      label-for="select-section"
      label-cols="3"
      style="text-align: center"
    >
      <b-form-select
        id="select-section"
        :options="sectionList"
        value-field="id"
        text-field="name"
        v-model="$v.session_date.section.$model"
        :state="validateState('section')"
        :disabled="!allows_crud"
        aria-describedby="select-section-feedback"
        size="sm"
      ></b-form-select>
      <b-form-invalid-feedback id="select-section-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form inline>
      <label class="col-sm-3 col-lg-3" for="select-init-date"
        >Fecha Inicial</label
      >
      <b-form-datepicker
        id="select-init-date"
        v-model="start_date"
        :disabled="!allows_crud"
        size="sm"
        class="col-sm-5 col-lg-5"
      ></b-form-datepicker>
      <b-form-timepicker
        id="select-init-time"
        v-model="start_time"
        :disabled="!allows_crud"
        size="sm"
        class="col-sm-4 col-lg-4"
      ></b-form-timepicker>
      <b-form-invalid-feedback
        :state="validateStartDateTime"
        style="text-align: center"
        >La fecha completa es obligatoria.</b-form-invalid-feedback
      >
    </b-form>
    <b-form inline>
      <label class="col-sm-3 col-lg-3" for="select-end-date">Fecha Final</label>
      <b-form-datepicker
        id="select-end-date"
        v-model="end_date"
        :disabled="!allows_crud"
        size="sm"
        class="col-sm-5 col-lg-5"
      ></b-form-datepicker>
      <b-form-timepicker
        id="select-end-time"
        v-model="end_time"
        :disabled="!allows_crud"
        size="sm"
        class="col-sm-4 col-lg-4"
      ></b-form-timepicker>
      <b-form-invalid-feedback
        :state="validateEndDateTime"
        style="text-align: center"
        >La fecha completa es obligatoria.</b-form-invalid-feedback
      >
    </b-form>
    <b-form inline v-if="isNaN(session_date.id)">
      <label class="col-sm-3 col-lg-3" for="select-repeat"
        >Repetir Semanalmente</label
      >
      <b-form-checkbox
        v-model="repeat"
        :disabled="!allows_crud"
        size="sm"
        class="col-sm-1 col-lg-1"
      ></b-form-checkbox>
      <!-- <b-form-select
        id="select-repeat"
        :options="repeat_options"
        value-field="value"
        text-field="text"
        v-model="repeat_type"
        :disabled="!allows_crud || !repeat"
        size="sm"
        class="col-sm-4 col-lg-4"
      ></b-form-select>-->
    </b-form>
    <div class="row">
      <div
        v-if="show_delete_button && allows_crud"
        class="col"
        style="text-align: left"
      >
        <b-dropdown
          v-if="isParent"
          right
          text="Eliminar"
          size="sm"
          variant="danger"
        >
          <b-dropdown-item>Este evento</b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item>Todos los eventos</b-dropdown-item>
        </b-dropdown>
        <b-button
          v-else
          class="mr-1"
          size="sm"
          variant="danger"
          @click="askForDelete"
          >Eliminar</b-button
        >
      </div>
      <div v-if="allows_crud" class="col" style="text-align: right">
        <b-button class="mr-1" size="sm" @click="save">Guardar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { generateUniqueId, toast } from "@/utils/utils";
import moment from "moment";

export default {
  name: "SessionDateForm",
  mixins: [validationMixin],
  props: {
    SessionDate: {
      type: Object,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
    show_delete_button: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      session_date: {
        id: this.SessionDate ? this.SessionDate.id : generateUniqueId(),
        start_date: null,
        end_date: null,
        section: this.SessionDate ? this.SessionDate.section : null,
        parent: this.SessionDate ? this.SessionDate.parent : null,
      },
      start_date: null,
      start_time: null,
      end_date: null,
      end_time: null,
      repeat: false,
      repeat_type: 1,
      repeat_options: [
        { value: 1, text: "Semanal" },
        { value: 2, text: "Mensual" },
      ],
    };
  },
  validations() {
    return {
      session_date: {
        start_date: { required },
        end_date: { required },
        section: { required },
      },
      start_date: { required },
      start_time: { required },
    };
  },
  computed: {
    ...mapGetters({
      sections: names.SECTIONS,
      matters: names.MATTERS,
      periods: names.PERIODS,
      sessionDates: names.SESSION_DATES,
    }),
    sectionList() {
      return this.sections;
    },
    validateStartDateTime() {
      return this.start_date && this.start_time ? true : false;
    },
    validateEndDateTime() {
      return this.end_date && this.end_time ? true : false;
    },
    isParent() {
      // if (this.session_date.parent) return true;
      // if (
      //   this.sessionDates.findIndex((x) => x.parent == this.session_date.id) !=
      //   -1
      // )
      //   return true;
      return false;
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.session_date[name];
      return $dirty ? !$error : null;
    },
    validateNativeState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    save() {
      this.$v.session_date.$touch();
      if (this.$v.session_date.$anyError) {
        return;
      }
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      if (isNaN(this.session_date.id)) this.create();
      else this.update();
    },
    create() {
      if (!this.allows_crud) {
        this.$emit("create", this.session_date);
        return;
      }
      if (this.repeat) {
        this.createMultipleInstances();
      } else {
        this.$store
          .dispatch(names.POST_SESSION_DATE, this.session_date)
          .then((response) => {
            this.session_date = response;
            this.$emit("created", this.session_date);
            toast("Evento creado.");
          });
      }
    },
    createMultipleInstances() {
      const section = this.sectionList.find(
        (x) => x.id == this.session_date.section
      );
      const period = this.periods.find((x) => x.id == section.period);
      if (!period) {
        console.log("Período no dispoible.");
        this.$swal(
          String(
            this.$getVisibleNames("mesh.period", false, "Período") +
              " no dispoible. No se pueden crear los eventos."
          )
        );
        return;
      }
      const period_init_date = moment(period.start_date);
      const period_end_date = moment(period.end_date);
      const matter = this.matters.find((x) => x.id == section.matter);
      if (!matter) {
        this.$swal(
          String(
            this.$getVisibleNames("mesh.period", false, "Período") +
              " no dispoible. No se pueden crear los eventos."
          )
        );
        return;
      }
      const begin_date = moment(this.session_date.start_date);
      const final_date = moment(this.session_date.end_date);
      let session_date = {
        start_date: begin_date.format("YYYY-MM-DD HH:mm:ss"),
        end_date: final_date.format("YYYY-MM-DD HH:mm:ss"),
        section: this.session_date.section,
      };
      this.$store
        .dispatch(names.POST_SESSION_DATE, session_date)
        .then((parent_session_date) => {
          let upper_counter = 1;
          let lower_counter = 1;
          let tmp_session_dates = [];
          while (upper_counter != 0 || lower_counter != 0) {
            let upper_init_date = begin_date.clone().add(upper_counter, "week");
            let upper_end_date = final_date.clone().add(upper_counter, "week");
            if (upper_end_date <= period_end_date && upper_counter != 0) {
              tmp_session_dates.push({
                start_date: upper_init_date.format("YYYY-MM-DD HH:mm:ss"),
                end_date: upper_end_date.format("YYYY-MM-DD HH:mm:ss"),
                section: this.session_date.section,
                parent: parent_session_date.id,
              });
              upper_counter += 1;
            } else {
              upper_counter = 0;
            }
            let lower_init_date = begin_date
              .clone()
              .subtract(lower_counter, "week");
            let lower_end_date = final_date
              .clone()
              .subtract(lower_counter, "week");
            if (lower_init_date >= period_init_date && lower_counter != 0) {
              tmp_session_dates.push({
                start_date: lower_init_date.format("YYYY-MM-DD HH:mm:ss"),
                end_date: lower_end_date.format("YYYY-MM-DD HH:mm:ss"),
                section: this.session_date.section,
                parent: parent_session_date.id,
              });
              lower_counter += 1;
            } else {
              lower_counter = 0;
            }
          }
          tmp_session_dates.sort(function (a, b) {
            if (a.start_date < b.start_date) return -1;
            if (a.start_date > b.start_date) return 1;
            return 0;
          });
          this.$store.dispatch(
            names.POST_BULK_SESSION_DATES,
            tmp_session_dates
          );
          this.$emit("created_bulk", this.session_date);
          toast("Múltiples Eventos creados.");
        });
    },
    update() {
      if (!this.allows_crud) {
        this.$emit("update", this.session_date);
        return;
      }
      this.$store
        .dispatch(names.UPDATE_SESSION_DATE, this.session_date)
        .then((response) => {
          this.session_date = response;
          this.$emit("updated", response);
          toast("Evento modificado.");
        });
    },
    askForDelete() {
      if (isNaN(this.session_date.id)) {
        this.$emit("delete", this.SessionDate);
        return;
      }
      this.$swal({
        title: "¿Está seguro de que desea eliminar el Evento?",
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) this.delete();
      });
    },
    delete() {
      this.$store
        .dispatch(names.DELETE_SESSION_DATE, this.session_date.id)
        .then(() => {
          this.$emit("deleted", this.session_date);
          toast("Evento eliminado.");
        });
    },
    createStartDateTime() {
      if (!this.start_date || !this.start_time) return;
      this.session_date.start_date = this.start_date + " " + this.start_time;
    },
    createEndDateTime() {
      if (!this.end_date || !this.end_time) return;
      this.session_date.end_date = this.end_date + " " + this.end_time;
    },
  },
  watch: {
    start_date: "createStartDateTime",
    start_time: "createStartDateTime",
    end_date: "createEndDateTime",
    end_time: "createEndDateTime",
    "session_date.section"() {
      const section = this.sectionList.find(
        (x) => x.id == this.session_date.section
      );
      this.$store.dispatch(names.FETCH_PERIOD, section.period);
    },
  },
  created() {
    if (this.SessionDate) {
      if (this.SessionDate.start) {
        // console.log(moment(this.SessionDate.start).format("YYYY-MM-DD hh:mm:ss"))
        let date = moment(this.SessionDate.start).format("YYYY-MM-DD");
        let time = moment(this.SessionDate.start).format("HH:mm:ss");
        this.start_date = date;
        this.start_time = time;
      }
      if (this.SessionDate.end) {
        let date = moment(this.SessionDate.end).format("YYYY-MM-DD");
        let time = moment(this.SessionDate.end).format("HH:mm:ss");
        this.end_date = date;
        this.end_time = time;
      }
    }
  },
};
</script>

<style>
</style>